import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { analytics } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { info } from '@assets/info';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService
  ) {}

  name = this.route.snapshot.params.name;
  info = {
    image: '',
    fullname: { th: '', en: '' },
    nickname: { th: '', en: '' },
    position: { th: '', en: '' },
    quote: { th: '', en: '' },
    mobile: '',
    email: '',
    vcard: '',
    line: ''
  };

  products = [
    {
      image: "./assets/images/logo-mit.png",
      link: "https://www.meetintouch.com/"
    },
    {
      image: "./assets/images/logo-codesk.png",
      link: "https://www.codeskworkplace.com/"
    },
    {
      image: "./assets/images/logo-locker.png",
      link: "https://lockerspace.co/"
    },
    {
      image: "./assets/images/logo-workplace-plus.png",
      link: "https://www.exzyworkplaceplus.com"
    },
    {
      image: "./assets/images/logo-hikvision.png",
      link: "https://exzyworkplaceplus.com/products/hikvision-smart-interactive-whiteboard/"
    },
  ]

  ngOnInit() {
    analytics.logEvent('open_page_contact', { name: this.name });

    /* check data valid */
    if (!info[this.name]) {
      this.router.navigate(['/contact', { name: 'link' }]);
      this.info = info['link'];
    } else {
      this.info = info[this.name];
    }
  }

  call() {
    analytics.logEvent('click_call', { name: this.name });
  }

  mail() {
    analytics.logEvent('click_mail', { name: this.name });
  }

  download() {
    analytics.logEvent('click_download', { name: this.name });
  }

  line() {
    analytics.logEvent('click_line', { name: this.name });
  }

  goToMeetInTouch() {
    analytics.logEvent('click_link', { to: 'meet-in-touch', name: this.name });
  }

  goToCoDesk() {
    analytics.logEvent('click_link', { to: 'co-desk', name: this.name });
  }

  goToMeetInNote() {
    analytics.logEvent('click_link', { to: 'meet-in-note', name: this.name });
  }

  goToX10() {
    analytics.logEvent('click_link', { to: 'x-10', name: this.name });
  }
}
