import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(public translateService: TranslateService) {}
  lang;

  ngOnInit() {
    this.lang = this.translateService.currentLang;
  }

  changeLanguage(language: string) {
    this.translateService.use(language);
  }
}
