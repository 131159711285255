import { Component } from '@angular/core';
import { environment } from '@environments/environment';

const firebaseConfig = environment.firebaseConfig;

import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { TranslateService } from '@ngx-translate/core';
firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('th');
    this.translateService.use('th');
  }
}
