import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { analytics } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { info } from '@assets/info';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss']
})
export class SaleComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService
  ) {}

  name = this.route.snapshot.params.name;
  info = {
    image: '',
    fullname: { th: '', en: '' },
    nickname: { th: '', en: '' },
    position: { th: '', en: '' },
    quote: { th: '', en: '' },
    mobile: '',
    email: '',
    vcard: '',
    line: ''
  };

  qrCodeUrl = window.location.origin + '/contact/' + this.route.snapshot.params.name;

  ngOnInit() {
    analytics.logEvent('open_page_sale', { name: this.name });

    /* check data valid */
    if (!info[this.name]) {
      this.router.navigate(['/contact', { name: 'link' }]);
      this.info = info['link'];
    } else {
      this.info = info[this.name];
    }
  }

  goToMeetInTouch() {
    analytics.logEvent('click_link', { to: 'meet-in-touch', name: this.name });
  }

  goToCoDesk() {
    analytics.logEvent('click_link', { to: 'co-desk', name: this.name });
  }

  goToMeetInNote() {
    analytics.logEvent('click_link', { to: 'meet-in-note', name: this.name });
  }
}
