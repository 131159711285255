import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

@Component({
  selector: 'app-profile-model',
  templateUrl: './profile-model.component.html',
  styleUrls: ['./profile-model.component.scss']
})
export class ProfileModelComponent implements OnInit, AfterViewInit {
  @Input() name: string;
  @ViewChild('rendererContainer') rendererContainer: ElementRef;

  renderer = new THREE.WebGLRenderer({ antialias: true, powerPreference: 'low-power' });
  scene = null;
  camera = null;
  controls = null;
  model = null;

  isLoading = true;

  constructor() {
    /* init */
    this.init3D();
  }

  ngAfterViewInit() {
    /* start render */
    this.render();
  }

  ngOnInit() {
    /* load model */
    this.load();
  }

  init3D() {
    this.renderer.setSize(180, 180);

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(50, 180 / 180, 0.1, 1000);
    this.camera.position.set(0, 20, 100);
    this.camera.lookAt(0, 0, 0);

    // this.controls = new DeviceOrientationControls(this.camera);
    // this.controls.connect();
    // this.controls.update();
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableZoom = false;
    this.controls.enablePan = false;
    this.controls.minPolarAngle = Math.PI / 3;
    this.controls.maxPolarAngle = Math.PI / 2;

    /* Sky Box */
    let bgMesh;
    const loader = new THREE.TextureLoader();
    loader.load(
      // 'https://threejsfundamentals.org/threejs/resources/images/equirectangularmaps/tears_of_steel_bridge_2k.jpg',
      'assets/images/skybox.jpg',
      texture => {
        texture.magFilter = THREE.LinearFilter;
        texture.minFilter = THREE.LinearFilter;

        const shader = THREE.ShaderLib.equirect;
        const material = new THREE.ShaderMaterial({
          fragmentShader: shader.fragmentShader,
          vertexShader: shader.vertexShader,
          uniforms: shader.uniforms,
          depthWrite: false,
          side: THREE.BackSide
        });
        material.uniforms.tEquirect.value = texture;
        const plane = new THREE.BoxBufferGeometry(500, 500, 500);
        bgMesh = new THREE.Mesh(plane, material);
        this.scene.add(bgMesh);
      }
    );
  }

  render() {
    requestAnimationFrame(this.render.bind(this));
    if (this.rendererContainer) {
      this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
      this.renderer.render(this.scene, this.camera);
      this.controls.update();
    }
  }

  load() {
    this.isLoading = true;
    var loader = new FBXLoader();
    loader.load(
      'assets/models/' + this.name + '.fbx',
      model => {
        const texture = new THREE.TextureLoader().load('assets/models/' + this.name + '.png');
        model.traverse(child => {
          if (child['material']) {
            child['material'] = new THREE.MeshBasicMaterial({ map: texture });
          }
        });
        model.scale.set(190, 190, 190);
        model.position.set(0, -15, 0);
        this.model = model;
        this.scene.add(this.model);
        this.isLoading = false;
        // console.log('MODEL', model);
      },
      undefined,
      error => {
        this.isLoading = true;
        // console.error('Error', error);
      }
    );
  }
}
