// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAiWvnJSxJDPBDZS6eNhyAxvU_8v7qlXyk',
    authDomain: 'digitalbusinesscard-62349.firebaseapp.com',
    databaseURL: 'https://digitalbusinesscard-62349.firebaseio.com',
    projectId: 'digitalbusinesscard-62349',
    storageBucket: 'digitalbusinesscard-62349.appspot.com',
    messagingSenderId: '537225478613',
    appId: '1:537225478613:web:f8adb553a2d4c280be3d5c',
    measurementId: 'G-VMDK19GG9W'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
