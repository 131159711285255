export const info = {
  link: {
    image: 'assets/images/link.jpg',
    fullname: { th: 'จอมทรัพย์ สิทธิพิทยา', en: 'Jomzup Sittipittaya' },
    nickname: { th: 'ลิงค์', en: 'Link' },
    position: { 
      th: '"Innovation Director - Smart Office Transformation"', 
      en: '"Innovation Director - Smart Office Transformation"' 
    },
    quote: {
      th: 'Excellence by Design, Advance by Technology',
      en: 'Excellence by Design, Advance by Technology',
    },
    mobile: '+6696-959-5193',
    email: 'jomzup@exzy.me',
    vcard: 'assets/files/jomzup.vcf',
    line: 'http://line.me/ti/p/~jomzup',
  },
  peemai: {
    image: 'assets/images/peemai.png',
    fullname: { th: 'รพีพงศ์ สีกสิกรณ์', en: 'Rapeephong Srikasikorn' },
    nickname: { th: 'ปีใหม่', en: 'Peemai' },
    position: { th: 'เจ้าหน้าที่บริหารงานขายโซลูชัน', en: 'Solution Sales Executive' },
    quote: { th: 'อย่าคาดหวังอะไรใหม่ๆ จากการกระทำแบบเดิมๆ', en: "Dont' be busy, Be Productive" },
    mobile: '+6662-323-2537',
    email: 'rapeephong@exzyteam.com',
    vcard: 'assets/files/rapeephong.vcf',
    line: '',
  },
  june: {
    image: 'assets/images/june.png',
    fullname: { th: 'ณัฐฐา ตั้งทองปั้น', en: 'Natta Tangthongpan' },
    nickname: { th: 'จูน', en: 'June' },
    position: { th: 'เจ้าหน้าที่บริหารงานขายโซลูชัน', en: 'Solution Sales Executive' },
    quote: { th: 'ทำวันนี้ให้ดีกว่าเมื่อวาน', en: 'Do the best' },
    mobile: '+6662-343-2537',
    email: 'natta@exzyteam.com',
    vcard: 'assets/files/natta.vcf',
    line: 'http://line.me/ti/p/~exzy_june',
  },
  pun: {
    image: 'assets/images/pun.png',
    fullname: { th: 'ธนชัย เกียรติวีระศักดิ์', en: 'Thanachai Kiattiweerasak' },
    nickname: { th: 'ปั้น', en: 'Pun' },
    position: { th: 'เจ้าหน้าที่ฝ่ายธุรกิจเทคโนโลยี', en: 'Tech Business Executive' },
    quote: { th: 'อย่าหยุดเรียนรู้ เพราะชีวิตไม่เคยหยุดสอนเรา', en: 'Stay Hungry...Stay Foolish' },
    mobile: '+6697-098-1634',
    email: 'thanachai@exzyteam.com',
    vcard: 'assets/files/thanachai.vcf',
    line: 'http://line.me/ti/p/~thanachai-pun',
  },
  pop: {
    image: 'assets/images/pop.png',
    fullname: { th: 'เนนิน อนันต์บัญชาชัย', en: 'Nenin Ananbanchachai' },
    nickname: { th: 'ป๊อบ', en: 'Pop' },
    position: { th: 'Co-Founder & CTO', en: 'Co-Founder & CTO' },
    quote: { th: 'ยอดไม้อ่อนโยน ยอดคนอ่อนน้อม', en: 'ยอดไม้อ่อนโยน ยอดคนอ่อนน้อม' },
    mobile: '+6683-887-6615',
    email: 'nenin@exzy.me',
    vcard: 'assets/files/nenin.vcf',
    line: 'http://line.me/ti/p/~neninx10',
  },
  aod: {
    image: 'assets/images/aod.png',
    fullname: { th: 'ชัยวัฒน์ รัตนะ', en: 'Chaiwat Ratana' },
    nickname: { th: 'อ็อด', en: 'Aod' },
    position: { th: 'ผู้ช่วยผู้จัดการโครงการ', en: 'Assistant Project Manager' },
    quote: { th: 'ความคิดสร้างชีวิต', en: 'Our life is what our thoughts make it' },
    mobile: '+6685-069-5082',
    email: 'chaiwat@exzyteam.com',
    vcard: 'assets/files/chaiwat.vcf',
    line: 'http://line.me/ti/p/~pentoraot',
  },
  bird: {
    image: 'assets/images/bird.jpg',
    fullname: { th: 'นพพล อังคะอุทัยวร', en: 'Nopphol Angkhauthaiworn' },
    nickname: { th: 'เบิร์ด', en: 'Bird' },
    position: { th: 'เจ้าหน้าฝ่ายสนับสนุนด้าน IT', en: 'IT Support' },
    quote: { th: '...', en: '...' },
    mobile: '+6692-556-6954',
    email: 'nopphol@exzyteam.com',
    vcard: 'assets/files/nopphol.vcf',
    line: '',
  },
  to: {
    image: 'assets/images/to2.png',
    fullname: { th: 'ชานนท์ พรหมแก้ว', en: 'Chanon Promkaew' },
    nickname: { th: 'โต', en: 'To' },
    position: { th: 'อาร์ตไดเรกเตอร์', en: 'Art Director' },
    quote: { th: '...', en: '...' },
    mobile: '+6683-716-6367',
    email: 'chanon@exzy.me',
    vcard: 'assets/files/chanon.vcf',
    line: 'http://line.me/ti/p/~tochanon',
  },
  mim: {
    image: 'assets/images/mim.jpg',
    fullname: { th: 'บุษยาภรณ์ ยูโซะ', en: 'Bussayabhorn Yusoh' },
    nickname: { th: 'มิม', en: 'Mim' },
    position: { th: 'Senoir UI&Graphic Designer', en: 'Senoir UI&Graphic Designer' },
    quote: {
      th: 'จงทำงานด้วยความรัก ไม่งั้นก็อย่าทำเลยดีกว่า',
      en: 'Do it with passion, or not at all.',
    },
    mobile: '+6686-608-4742',
    email: 'bussayabhorn@exzyteam.com',
    vcard: 'assets/files/bussayabhorn.vcf',
    line: 'http://line.me/ti/p/~mimmimyuso',
  },
  gok: {
    image: 'assets/images/gok.jpg',
    fullname: { th: 'พัฒนศักดิ์ เอื้อวิจิตรพจนา', en: 'Pattanasak Uewichitrapochana' },
    nickname: { th: 'ก็อก', en: 'Gok' },
    position: { th: 'วิศวกรซอฟต์แวร์อาวุโส', en: 'Senior Software Engineer' },
    quote: {
      th: "It's nice to be important, but it is more important to be nice",
      en: "It's nice to be important, but it is more important to be nice",
    },
    mobile: '+6683-031-5003',
    email: 'pattanasak@exzy.me',
    vcard: 'assets/files/pattanasak.vcf',
    line: 'http://line.me/ti/p/~zeneroth',
  },
  nin: {
    image: 'assets/images/nin.jpg',
    fullname: { th: 'คณิน ปฐมวัฒนานุรักษ์', en: 'Kanin Pathomwattananuruk' },
    nickname: { th: 'นิน', en: 'Nin' },
    position: { th: 'วิศวกรซอฟต์แวร์', en: 'Software Engineer' },
    quote: {
      th: 'ไม่มีคำว่าสายสำหรับการเรียนรู้ใหม่ๆ',
      en: "It's never too late for a new learning",
    },
    mobile: '+6682-723-5886',
    email: 'kanin@exzyteam.com',
    vcard: 'assets/files/kanin.vcf',
    line: 'http://line.me/ti/p/~kaninkung',
  },
  ink: {
    image: 'assets/images/white.png',
    fullname: { th: 'ฉันท์ทัต อ้นสว่าง', en: 'Chanthat Onsawang' },
    nickname: { th: 'อิ๊งค์', en: 'Ink' },
    position: { th: 'วิศวกรซอฟต์แวร์อาวุโส', en: 'Senior Software Engineer' },
    quote: {
      th: 'มองให้ไกล ไปให้สุด',
      en: 'Dream high, work hard',
    },
    mobile: '+6686-152-1963',
    email: 'chanthat@exzyteam.com',
    vcard: 'assets/files/chanthat.vcf',
    line: 'http://line.me/ti/p/~inkirby',
  },
  aof: {
    image: 'assets/images/aof.jpg',
    fullname: { th: 'ยศนันทน์ ปัตตพงศ์', en: 'Yosanun Pattaphongse' },
    nickname: { th: 'อิ้งค์', en: 'Ink' },
    position: { th: 'Software Quality Assurance Engineer', en: 'วิศวกรรับประกันคุณภาพซอฟต์แวร์' },
    quote: {
      th: 'เพราะคุณภาพคือความรับผิดชอบของทุกคน',
      en: "Quality Is Everyone's Responsibility",
    },
    mobile: '+6662-454-8999',
    email: 'yosanun@exzyteam.com',
    vcard: 'assets/files/yosanun.vcf',
    line: 'http://line.me/ti/p/~aofyosanun',
  },
  pike: {
    image: 'assets/images/pike.jpg',
    fullname: { th: 'ภัทรพล พัชรโอฬาร', en: 'Patarapon Patcharaolan' },
    nickname: { th: 'ไปค์', en: 'Pike' },
    position: { th: 'วิศวกรซอฟต์แวร์อาวุโส', en: 'Senior Software Engineer' },
    quote: {
      th: 'ทำวันนี้ให้ดีที่สุดเพื่อวันพรุ้งนี้เป็นวันของเรา',
      en: 'Do your best Today for a better Tomorrow',
    },
    mobile: '+6680-652-2898',
    email: 'patarapon@exzyteam.com',
    vcard: 'assets/files/patarapon.vcf',
    line: 'http://line.me/ti/p/~pikeyoo',
  },
  pear: {
    image: 'assets/images/pear.jpg',
    fullname: { th: 'ทิพากร ธนวรรณรัชต์', en: 'Tipakorn Thanawannarat' },
    nickname: { th: 'แพร', en: 'Pear' },
    position: { th: 'วิศวกรซอฟต์แวร์', en: 'Software Engineer' },
    quote: {
      th: 'ถ้าคุณรักงานคุณ สนุกกับมัน นั่นถือว่าคุณประสบความสำเร็จแล้ว',
      en: "If you love your work, if you enjoy it, you're already a success",
    },
    mobile: '+6680-485-2044',
    email: 'tipakorn@exzyteam.com',
    vcard: 'assets/files/tipakorn.vcf',
    line: '',
  },
  cha: {
    image: 'assets/images/cha.jpg',
    fullname: { th: 'อนุชา นวลศรี', en: 'Anucha Nualsi' },
    nickname: { th: 'ชา', en: 'Cha' },
    position: { th: 'วิศวกรซอฟต์แวร์อาวุโส', en: 'Senior Software Engineer' },
    quote: {
      th: 'วิธีที่ดีที่สุดในการทำนายอนาคต คือการสร้างมันขึ้นมา',
      en: 'The best way to predict the future is to invent it',
    },
    mobile: '+6665-552-9919',
    email: 'anucha@exzyteam.com',
    vcard: 'assets/files/anucha.vcf',
    line: 'http://line.me/ti/p/~ana.cpe9',
  },
  him: {
    image: 'assets/images/him.jpg',
    fullname: { th: 'ธนัตถ์กรณ์ ชื่นบรรลือสุข', en: 'Thanatkorn Chuenbanluesuk' },
    nickname: { th: 'ฮิม', en: 'Him' },
    position: { th: 'วิศวกรซอฟต์แวร์', en: 'Software Engineer' },
    quote: {
      th: 'JUST DO IT!',
      en: 'JUST DO IT!',
    },
    mobile: '+6685-801-5620',
    email: 'thanatkorn@exzyteam.com',
    vcard: 'assets/files/thanatkorn.vcf',
    line: 'http://line.me/ti/p/~teehimz',
  },
  bamm: {
    image: 'assets/images/bamm.jpg',
    fullname: { th: 'ภาวัต ยุทธธีรนนท์', en: 'Pawat Yuttateeranont' },
    nickname: { th: 'แบม', en: 'Bamm' },
    position: { th: 'วิศวกรซอฟต์แวร์', en: 'Software Engineer' },
    quote: {
      th: 'ทำทีละหนึ่ง, แต่คิดให้มาก',
      en: 'Think of many things; do one.',
    },
    mobile: '+6689-443-1211',
    email: 'pawat@exzyteam.com',
    vcard: 'assets/files/pawat.vcf',
    line: '',
  },
  pae: {
    image: 'assets/images/pae.jpg',
    fullname: { th: 'รัชชัย ดำเนินกิตติกุล', en: 'Ratchai Damnoenkittikun' },
    nickname: { th: 'เป้', en: 'Pae' },
    position: { th: 'หัวหน้าฝ่ายพัฒนาผลิตภัณฑ์', en: 'Chief Development Officer' },
    quote: {
      th:
        'Imagination is important than knowledge, but "KNOWN-ledge" is important than imagination',
      en:
        'Imagination is important than knowledge, but "KNOWN-ledge" is important than imagination',
    },
    mobile: '+6689-890-7577',
    email: 'ratchai@exzy.me',
    vcard: 'assets/files/ratchai.vcf',
    line: '',
  },
  nan: {
    image: 'assets/images/nan.png',
    fullname: { th: 'ลลิล อนันต์บัญชาชัย', en: 'Lalin Ananbanchachai' },
    nickname: { th: 'แนน', en: 'Nan' },
    position: { th: 'COO & CO-Founder', en: 'COO & CO-Founder' },
    quote: {
      th: '...',
      en: '...',
    },
    mobile: '+6692-474-4258',
    email: 'lalin@exzy.me',
    vcard: 'assets/files/lalin.vcf',
    line: '',
  },
  sarawut: {
    image: 'assets/images/sarawut.png',
    fullname: { th: 'สราวุธ วรากูล', en: 'Sarawut Warakul' },
    nickname: { th: 'เก่ง', en: 'Keng' },
    position: { th: 'เจ้าหน้าที่ซัพพอร์ตการขายโครงการ', en: 'Pre-Sales'},
    quote: {
      th: 'การมีเป้าหมายที่ชัดเจน คือจุดเริ่มต้นของทุกๆ ความสำเร็จ',
      en: 'การมีเป้าหมายที่ชัดเจน คือจุดเริ่มต้นของทุกๆ ความสำเร็จ',
    },
    mobile: '+6684-698-5754',
    email: 'sarawut@exzyteam.com',
    vcard: 'assets/files/sarawut.vcf',
    line: 'http://line.me/ti/p/~kengect'
  },
  thidarat: {
    image: 'assets/images/thidarat.png',
    fullname: { th: 'ธิดารัตน์ ไชยเลิศ', en: 'Thidarat Chailerd' },
    nickname: { th: 'ปอย', en: 'Poy' },
    position: { th: 'เจ้าหน้าที่ขายโครงการ', en: 'Project Sales'},
    quote: {
      th: 'ไม่มีความสำเร็จใด ที่จะไม่ใช้ความพยายามและเวลา',
      en: 'ไม่มีความสำเร็จใด ที่จะไม่ใช้ความพยายามและเวลา',
    },
    mobile: '+6692-695-6191',
    email: 'thidarat@exzyteam.com',
    vcard: 'assets/files/thidarat.vcf',
    line: 'http://line.me/ti/p/~thidapoy.'
  },
};
