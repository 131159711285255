import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { SaleComponent } from './sale/sale.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: AppComponent
  // },
  { path: 'contact/:name', component: ContactComponent },
  { path: 'sale/:name', component: SaleComponent },
  { path: '**', redirectTo: 'contact/link', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
